import { render, staticRenderFns } from "./TextMessage.vue?vue&type=template&id=40b0f4dc&"
import script from "./TextMessage.vue?vue&type=script&lang=js&"
export * from "./TextMessage.vue?vue&type=script&lang=js&"
import style0 from "./TextMessage.vue?vue&type=style&index=0&id=40b0f4dc&prod&lang=css&"
import style1 from "@/assets/css/youtube/yt-live-chat-text-message-renderer.css?vue&type=style&index=1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports